import React from 'react';
import { SubmitButton } from '../elements/Button';
import Input from '../elements/Input';
import UserInput from '../elements/UserInput';

function MessageInput(props) {
  function handleFormSubmit(e) {
    e.preventDefault();

    // eslint-disable-next-line no-alert
    if (!props.typedMessage) return alert('You need to type a message!');
    return props.addMessage();
  }

  return (
    <div className="input-area">
      <form method="post" onSubmit={e => handleFormSubmit(e)}>
        <UserInput>
          <Input
            type="text"
            name="message-content"
            placeholder="Your message here...."
            aria-label="Enter your message"
            onChange={e => props.setTypedMessage(e.target.value)}
            value={props.typedMessage}
            data-test-id="message-content"
            ref={props.inputRef}
            autoComplete="off"
          />
          <SubmitButton
            type="submit"
            className="reset-btn"
            data-test-id="send-message"
            name="send-message"
          >
            ⪢
          </SubmitButton>
        </UserInput>
      </form>
    </div>
  );
}
export default MessageInput;
