const validateDate = require('validate-date');

export function validateFullNameLength(fullName, maximumLengthUsername) {
  return fullName.length <= maximumLengthUsername;
}

export function validateEmail(email) {
  // source https://emailregex.com/
  // eslint-disable-next-line no-useless-escape
  const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regEmail.test(email);
}

export function isValidDate(DD, MM, YYYY) {
  return validateDate(`${MM}/${DD}/${YYYY}`) === 'Valid Date';
}

export function isOverAge(DD, MM, YYYY, age = 18) {
  const today = new Date();
  // coerce strings from the DOM
  return new Date(+YYYY + age, +MM - 1, +DD) <= today;
}
