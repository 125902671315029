import styled from 'styled-components';

export default styled.header`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 10vh;
  background-color: ${props => props.theme.primary};
  margin-bottom: 3vh;
  color: ${props => props.theme.headerText || '#ffffff'};
  padding: 0 2em;
  ul {
    text-align: right;
    padding: 0;
    list-style: none;
    color: ${props => props.theme.navigationMenuText};
    li {
      font-size: 1rem;
      display: inline;
      &:not(:last-of-type) {
        margin-right: 0.75rem;
      }
    }
  }
  img {
    width: 13vw;
  }
  #burger-container {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    #burger-container {
      width: 20px;
      height: 20px;
      display: block;
      color: #808080;
    }
    .burger-line {
      width: 20px;
      height: 3px;
      background-color: ${props => props.theme.burgerColor};
      border-radius: 2px;
      display: block;
      margin: 3px;
    }
    ul {
      display: none;
    }
    img {
      width: 34vw;
    }
    padding: 1vw 10vw;
    // width: 80vw;
  }
  .burgerList {
    display: flex;
    flex-direction: column;
    color: black;
    text-align: left;
    position: absolute;
    right: 1em;
    left: auto;
    z-index: 99;
  }
  li {
    padding: 5px 10px;
    margin-right: 10px;
    width: 90%;
    transition: ease-out 0.2s;
    cursor: pointer;
    text-align: center;
    border-radius: 6px;
  }
  li:hover {
    background-color: #f9f9f9;
    color: #181818;
    transition: ease-in 0.2s;
    transform: scale(1.1);
  }

  @media only screen and (min-width: 600px) {
    .burgerList {
      display: none;
    }
  }
`;
