/* eslint-disable no-confusing-arrow */
// eslint-disable-next-line object-curly-newline
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react';
import MessageInput from './MessageInput';
import MultipleChoiceButton from '../elements/MultipleChoiceButton';
import SingleMessage from './SingleMessage';
import TypingAnimation from './TypingAnimation';
import { postToChat } from '../utils';
import ResetButton from '../elements/ResetButton';
import { ThemeContext } from '../contexts/Theme';

const uuidv4 = require('uuid/v4');

function Messages({ persona }) {
  const BOT_NAME = 'Dorris';
  const [typedMessage, setTypedMessage] = useState('');
  const [clickedMessage, setClickedMessage] = useState(false);
  const [messages, setMessages] = useState([
    {
      id: uuidv4(),
      type: 'bot',
      text: 'Hello, very nice to see you! What can I do for you today?',
    },
  ]);

  const [theme] = useContext(ThemeContext);

  const inputRef = useRef(null);

  const addMessage = useCallback(() => {
    // Add our new message to the current message array
    const getNewMessage = {
      id: uuidv4(),
      type: 'user',
      sessionId: localStorage.getItem('session_id'),
      text: typedMessage,
    };

    // Set the messages state to the copied messages, adding our new messages to our
    // state
    setMessages(currentMessages => [...currentMessages, getNewMessage]);

    // Add the new conversation to our conversation history in localStorage.
    localStorage.setItem(
      'conversation_history',
      JSON.stringify([...messages, getNewMessage]),
    );

    // Set the typedMessage state variable to nothing
    setTypedMessage('');

    // Make a call to our API, passing both our session_id (from localStorage) and
    // our message.

    return postToChat({
      sessionId: localStorage.getItem('session_id'),
      persona,
      text: typedMessage,
    }).then(({ text }) => {
      // Push our new message to our new copyMessagesAgain variable
      const getBotMessage = { id: uuidv4(), type: 'bot', text };

      // Set the messages state with the new messages, allowing us to add our new
      // messages to state
      setMessages(currentMessages => [...currentMessages, getBotMessage]);

      // Add our new message to our conversation history in localStorage
      localStorage.setItem(
        'conversation_history',
        JSON.stringify([...messages, getBotMessage]),
      );

      // Clear the input box
      inputRef.current.value = '';

      const getLastMessage = messages[messages.length - 1];
      const lastMessage = document.getElementById(getLastMessage.id);
      if (lastMessage) {
        lastMessage.scrollIntoView();
      }
    });
  }, [messages, persona, typedMessage]);

  useEffect(() => {
    
    if (messages.length === 1 && localStorage.getItem('conversation_history')) {
      const getConvoHistory = JSON.parse(
        localStorage.getItem('conversation_history'),
      );
      setMessages(getConvoHistory);
    }
  }, [messages.length]);

  useEffect(() => {
    if (clickedMessage === true) {
      setClickedMessage(false);
    }

    // Get the session id from local storage.
    const getSessionIdFromLocalStorage = localStorage.getItem('session_id');

    // If there is no session id in the local storage, then we will set this
    // session_id.
    if (!getSessionIdFromLocalStorage) {
      localStorage.setItem('session_id', uuidv4());
    }

    if (messages.length > 1) {
      inputRef.current.focus();
      // window.scrollTo(window.clientHeight);
    }
    const getLastMessage = messages[messages.length - 1];
    const lastMessage = document.getElementById(getLastMessage.id);
    if (lastMessage) {
      lastMessage.scrollIntoView();
    }
  }, [messages, addMessage, clickedMessage]);

  useEffect(() => {
    if (clickedMessage === true) {
      addMessage();
      setClickedMessage(false);
    }
  }, [addMessage, clickedMessage]);

  const formatList = json => JSON.parse(json);
  const resetConvo = () => {
    localStorage.clear();
    setMessages([
      {
        id: uuidv4(),
        type: 'bot',
        text: 'Hello, very nice to see you! What can I do for you today?',
      },
    ]);
  };
  const names = {
    goCompare: 'Geo',
    compareTheMarket: 'Sergei',
    compareCover: 'Dorris',
    northcoders: 'Ruth',
  };
  return (
    <>
      <main role="main">
        <h1 data-test-id="main-title" id="message-title">
          Speak to {names[theme] || 'Dorris'}
        </h1>
        <div className="message-area-formatting">
          <div className="message-area" id="all-messages">
            <ul className="messages" tabIndex="0">
              {messages.map(
                (message, index) =>
                  message.text.slice(0, 7) === '<$LIST>' ? (
                    <li key={message.id} className="multButtonAndQues">
                      <SingleMessage
                        messageId={message.id}
                        messageTitle={message.type}
                        messageContent={formatList(message.text.slice(7)).Q}
                        botName={BOT_NAME}
                      />
                      <div className="multipleChoiceButtons">
                        {formatList(message.text.slice(7)).A.map(
                          (answer, index) => (
                            <div
                              // eslint-disable-next-line react/no-array-index-key
                              key={message.id + index}
                              className="message-box-width"
                            >
                              <MultipleChoiceButton
                                onClick={() => {
                                  setTypedMessage(answer);
                                  setClickedMessage(true);
                                }}
                              >
                                {answer}
                              </MultipleChoiceButton>
                            </div>
                          ),
                        )}
                      </div>
                    </li>
                  ) : (
                    <li key={message.id} className="message-box-width">
                      <SingleMessage
                        key={message.id}
                        messageId={message.id}
                        messageTitle={message.type}
                        messageContent={message.text}
                        botName={BOT_NAME}
                      />
                      {message.type === 'user' &&
                      index === messages.length - 1 ? (
                        <TypingAnimation />
                      ) : (
                        <></>
                      )}
                    </li>
                  ),
                // eslint-disable-next-line react/jsx-curly-newline
              )}
            </ul>
            <MessageInput
              addMessage={addMessage}
              setTypedMessage={setTypedMessage}
              typedMessage={typedMessage}
              inputRef={inputRef}
              resetConvo={resetConvo}
              messageAmount={messages.length}
            />
          </div>
          {messages.length > 1 ? (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            <div className="send-reset-flex">
              <ResetButton
                onClick={() => resetConvo()}
                data-test-id="reset-convo"
              >
                RESTART
              </ResetButton>
            </div>
          ) : (
            ''
          )}
        </div>
      </main>
    </>
  );
}

export default Messages;
