/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';

export default styled.button`
  width: 8em;
  float: right;
  background-color: rgb(130, 130, 130);
  color: #fff;
  border: none;
  border-radius: 0 0 10px 10px;
  margin-top: 0;
  padding: 1em;
  font-size: 0.7em;
  transition: ease-out 0.3s;
  box-shadow: 0 5px 0.5rem rgb(20, 20, 20, 0.2);
  cursor: pointer;
  position: relative;
  left: 64vw;
  overflow: none;

  &:hover {
    background-color: rgb(240, 80, 80);
    transition: ease-in 0.3s;
    padding-top: 1.5em;
  }

  @media only screen and (max-width: 725px) {
    left: 60vw;
  }

  @media only screen and (max-width: 600px) {
    left: 65vw;
  }
  position: relative;
  animation-duration: 0.7s;
  animation-name: slidein;
  animation-iteration-count: 1;
  animation-direction: alternate;
  @keyframes slidein {
    from {
      bottom: 4em;
    }
    to {
      bottom: 0;
    }
  }
`;
