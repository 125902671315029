import styled from 'styled-components';

export default styled.footer`
  background-color: ${props => props.theme.tertiary};
  color: ${props => props.theme.text};
  .row-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  ul {
    list-style: none;
    padding: 0;
    color: ${props => props.theme.text};
    display: inline;
    margin-right: 4vw;
    li {
      display: inline;
      color: ${props => props.theme.text};
      &:not(:last-of-type) {
        margin-right: 1em;
      }
    }
  }
  img {
    width: 13vw;
  }
  padding: 4vh 2vw;
  p {
    font-size: 0.8em;
  }
  position: relative;
  bottom: 0;
  @media only screen and (max-width: 600px) {
    img {
      width: 34vw;
    }
  }
  #optionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;

    ul.ul-push {
      margin-left: 1em;
    }
  }
  select {
    display: inline-block;
    width: 100px;
    /* padding: 5px; */
    outline: 0;
    border: 0;
    background: #fff;
    color: #000;
  }
`;
