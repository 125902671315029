import React, { useContext } from 'react';
import FooterElement from '../elements/FooterElement';
import { ThemeContext } from '../contexts/Theme';

function Footer() {
  const [theme, setTheme] = useContext(ThemeContext);
  function handleThemeChange(event) {
    setTheme(event.target.value);
    event.preventDefault();
  }
  return (
    <FooterElement>
      <div className="row-flex">
        {theme !== 'legalAndGeneral' ? (
          <img alt={`${theme} logo`} src={`images/${theme}_logo.png`} />
        ) : null}

        <div id="optionsContainer">
          <ul className={theme !== 'legalAndGeneral' ? 'ul-push' : null}>
            <li>About</li>
            <li>Contact</li>
          </ul>
          <select
            data-test-id="select-theme"
            aria-label="Select Theme"
            onChange={handleThemeChange}
            value={theme}
          >
            <option value="goCompare">Go Compare</option>
            <option value="compareTheMarket">Compare The Market</option>
            <option value="compareCover">Compare Cover</option>
            <option value="northcoders">Northcoders</option>
            <option value="legalAndGeneral">Legal and General</option>
            <option value="confused">Confused</option>
          </select>
        </div>
      </div>

      <p>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Necessitatibus
        officia soluta corporis saepe excepturi natus, mollitia sunt, minus quia
        sint asperiores! Laudantium hic odio quas distinctio dolorum
        consequuntur, excepturi possimus impedit aliquam itaque magni eaque,
        veritatis aliquid necessitatibus reprehenderit minima nesciunt molestias
        quam quisquam velit.
      </p>
    </FooterElement>
  );
}

export default Footer;
