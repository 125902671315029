import styled from 'styled-components';

export const JoinedLeft = styled.div`
  min-width: 4rem;
  margin-left: 25px; /* see border-radius on JoinedForm */
  border: none;
  padding: 1rem 0;
  text-align: center;
  font-size: 1rem;
  color: #39393a;
`;

export const JoinedInput = styled.input`
  width: 100%;
  margin-right: 25px; /* see border-radius on JoinedForm */
  padding: 1rem;
  border: none;
  font-size: 1rem;
  color: #39393a;
`;

/* Parent element of JoinedLeft and JoinedInput */
export const JoinedForm = styled.div`
  display: flex;
  width: 100%;
  // max-width: 32rem;
  margin: 2vh 0;
  border: none;
  background-color: white;
  box-shadow: 0 5px 0.5rem rgb(20, 20, 20, 0.2);
  border-radius: 25px;
`;

export const DateInput = styled.input`
  width: calc(33.3333333% - 1rem); /* small devices */
  max-width: 6rem;
  padding: 0.5rem;
  margin: 0.5rem;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 25px;
  box-shadow: 0 5px 0.5rem rgb(20, 20, 20, 0.2);
`;

export const FormButton = styled.button`
  width: 10rem;
  border: none;
  border-radius: 25px;
  text-align: center;
  padding: 0.8em 0;
  margin: 3rem;
  font-size: 1.125em;
  font-weight: bold;
  color: #fff;
  background-color: ${props => props.theme.button};
  box-shadow: 0 5px 0.5rem rgb(20, 20, 20, 0.2);
  &:hover {
    background-color: ${props => props.theme.buttonHover};
    transition: ease-in 0.3s;
  }
`;
