import React, { useContext } from 'react';
import SingleMessageElement from '../elements/SingleMessageElement';
import Avatar from '../elements/Avatar';
import { ThemeContext } from '../contexts/Theme';

function SingleMessage(props) {
  const [theme] = useContext(ThemeContext);
  // eslint-disable-next-line no-confusing-arrow
  const avatarImage = () =>
    theme !== 'goCompare' && theme !== 'compareTheMarket' ? 'default' : theme;

  return (
    <div className="single-message-container">
      <Avatar
        alt="Avatar"
        src={
          props.messageTitle === 'bot'
            ? `images/${avatarImage()}_formal_avatar.png`
            : 'images/user_avatar.png'
        }
        messageType={props.messageTitle === 'bot' ? 'bot' : 'user'}
      />

      <SingleMessageElement
        key={props.messageId}
        id={props.messageId}
        messageType={props.messageTitle === 'bot' ? 'bot' : 'user'}
        data-test-id="message"
      >
        <p>{props.messageContent}</p>
      </SingleMessageElement>
      <div className="clear" />
    </div>
  );
}

export default SingleMessage;
