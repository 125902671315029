import React, { createContext, useState, useEffect } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import {
  goCompare,
  compareTheMarket,
  compareCover,
  northcoders,
  legalAndGeneral,
  confused,
} from '../styles/themes';

const themes = {
  goCompare,
  compareTheMarket,
  compareCover,
  northcoders,
  legalAndGeneral,
  confused,
};

const defaultTheme = compareCover;

export const ThemeContext = createContext({});

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('compareCover');

  useEffect(() => {
    const favicon = document.getElementById('favicon');
    favicon.href = `${theme}.ico`;
  });

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) setTheme(savedTheme);
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={[theme, setTheme]}>
      <StyledThemeProvider theme={themes[theme] || defaultTheme}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};
