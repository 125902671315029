import React from 'react';
import CircleAnim from '../elements/CircleAnim';

function TypingAnimation() {
  return (
    <span className="circleAnimContainer">
      <CircleAnim order="0.1s">●</CircleAnim>
      <CircleAnim order="0.3s">●</CircleAnim>
      <CircleAnim order="0.5s">●</CircleAnim>
    </span>
  );
}

export default TypingAnimation;
