import styled, { css } from 'styled-components';

export default styled.div`
  float: right;
  width: 40%;
  background-color: ${props => props.theme.secondary};
  border-radius: 4px;
  color: #fff;

  position: relative;
  border-radius: 0.4em;

  word-wrap: break-word;

  p {
    padding: 0.6em;
    margin: 0;
  }

  h2 {
    margin: 0;
    padding: 0.8em;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:not(:last-of-type) {
    margin-bottom: 1em;
  }

  small {
    display: block;
    padding: 0.6em;
    color: #fff;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  h2,
  small {
    background-color: #2d85ae;
    color: #fff;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-left-color: ${props => props.theme.secondary};
    border-right: 0;
    border-bottom: 0;
    margin-top: -10px;
    margin-right: -20px;
  }

  ${props =>
    props.messageType === 'bot' &&
    css`
      float: left;
      background-color: #f9f9f9;
      color: #505050;

      h2,
      small {
        background-color: #9867fa;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-right-color: #f9f9f9;
        border-left: 0;
        border-bottom: 0;
        margin-top: -10px;
        margin-left: -20px;
      }
    `}

  @media(max-width: 1100px) {
    width: 60%;
  }
`;
