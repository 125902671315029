import styled from 'styled-components';

export const Button = styled.button`
  width: 20%;
  float: right;
  background-color: ${props => props.theme.button};
  color: #fff;
  border: none;
  border-radius: 57px;
  margin-top: 2em;
  padding: 1em;
  font-size: 1em;
  transition: ease-out 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.buttonHover};
    transition: ease-in 0.3s;
  }
`;

export const SubmitButton = styled(Button)`
  font-size: 2em;
  width: 15%;
  text-align: center;
  padding: 0;
  margin-top: 0;
  margin: 0;
  color: gray;
  background-color: rgb(0, 0, 0, 0);
  &:hover {
    background-color: rgb(0, 0, 0, 0);
    color: black;
    transition: ease-in 0.3s;
  }
`;
