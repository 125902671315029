import styled, { keyframes } from 'styled-components';

const slidein = keyframes`
    from {
      font-size: 1em;
      opacity: 1;
    }
    to {
      font-size: 0.6em;
      opacity: 0.6;
    }
`;

export default styled.span`
  font-size: 1.2em;
  animation-delay: ${props => props.order};
  animation-duration: 0.6s;
  animation-name: ${slidein};
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;
