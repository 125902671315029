export const goCompare = {
  primary: 'rgb(107, 194, 74)',
  secondary: 'rgb(217, 110, 153)',
  tertiary: 'rgb(63, 63, 63)',
  background: 'rgb(245, 242, 240)',
  text: '#ffffff',
  button: 'rgb(217, 110, 153)',
  buttonHover: 'rgb(239, 55, 128)',
  burgerColor: '#fff',
};

export const compareTheMarket = {
  primary: 'rgb(7, 39, 96)',
  secondary: 'rgb(53, 169, 48)',
  tertiary: 'rgb(0, 74, 142)',
  background: 'rgb(242, 242, 242)',
  text: '#ffffff',
  button: 'rgb(54, 173, 48)',
  buttonHover: 'rgb(50, 161, 45)',
  burgerColor: '#fff',
};

export const compareCover = {
  primary: 'rgb(255,255,255)',
  secondary: 'rgb(108,117,125)',
  tertiary: 'rgb(0, 74, 142)',
  background: 'rgb(255,255,255)',
  navigationMenuText: '#808080',
  text: '#ffffff',
  button: '#ea5c37',
  buttonHover: '#cc3b15',
  burgerColor: '#00498D',
};

export const northcoders = {
  primary: 'rgb(32,40,41)',
  secondary: 'rgb(186,31,49)',
  tertiary: 'rgb(32,40,41)',
  background: 'rgb(255,255,255)',
  text: '#ffffff',
  button: '#ba1f31',
  buttonHover: '#C74753',
  burgerColor: '#fff',
};

export const legalAndGeneral = {
  primary: 'rgb(255,255,255)',
  secondary: 'rgb(0,127,170)',
  tertiary: 'rgb(0,127,170)',
  background: 'rgb(255,255,255)',
  text: '#eee',
  headerText: '#282828',
  button: 'rgb(0,135,68)',
  buttonHover: 'rgb(0,100,42)',
  burgerColor: '#282828',
};

export const confused = {
  primary: 'rgb(255,255,255)',
  secondary: 'rgb(0,127,170)',
  tertiary: '#eff8ff',
  background: 'rgb(255,255,255)',
  text: '#323232',
  headerText: '#282828',
  button: '#1C8FE9',
  buttonHover: 'rgb(0,100,42)',
  burgerColor: '#282828',
};
