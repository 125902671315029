import React, { useState } from 'react';
import { Router } from '@reach/router';
import { ThemeProvider } from './contexts/Theme';
import Messages from './components/Messages';
import UserInfo from './components/UserInfo';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';

const App = () => {
  const [persona, setPersona] = useState('informal');
  return (
    <ThemeProvider className="App">
      <Header />
      <Router>
        <UserInfo path="/" setPersona={setPersona} />
        <Messages path="/messages" persona={persona} />
      </Router>
      <Footer id="footer" />
    </ThemeProvider>
  );
};

export default App;
