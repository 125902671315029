/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import HeaderElement from '../elements/HeaderElement';
import { ThemeContext } from '../contexts/Theme';
import Accordion from './Accordion';

function Header() {
  // eslint-disable-next-line array-bracket-spacing
  const [theme] = useContext(ThemeContext);
  return (
    <HeaderElement>
      <img
        alt={`${theme} logo`}
        src={`images/${theme}_logo.png`}
        className={theme === 'legalAndGeneral' ? 'logoImgSmaller' : 'logoImg'}
        onClick={() => {
          navigate('/');
        }}
      />

      <ul>
        <li>Insurance</li>
        <li>Money</li>
        <li>Motoring</li>
        <li>Travel</li>
        <li>Energy</li>
      </ul>
      <div className="accor">
        <Accordion>
          <div className="accorMain">
            <div className="accorHead">
              <div id="burger-container">
                <span className="burger-line" />
                <span className="burger-line" />
                <span className="burger-line" />
              </div>
            </div>
            <ul className="accorBody burgerList">
              <li>Insurance</li>
              <li>Money</li>
              <li>Motoring</li>
              <li>Travel</li>
              <li>Energy</li>
            </ul>
          </div>
        </Accordion>
      </div>
    </HeaderElement>
  );
}

export default Header;
