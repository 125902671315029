import styled from 'styled-components';

export default styled.button`
  background-color: #f9f9f9;
  color: #505050;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 57px;
  padding: 0.7em;
  margin: 0 0.5em 0.5em 0.5em;
  font-size: 1em;
  transition: ease-out 0.2s;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.secondary};
    color: #fff;
    transition: ease-in 0.2s;
    transform: scale(1.1);
  }
`;
