import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import Accordion from './Accordion';
import AccorElement from '../elements/AccorElement';
import {
  JoinedInput,
  JoinedLeft,
  DateInput,
  FormButton,
  JoinedForm,
} from '../elements/FormElement';
import {
  validateFullNameLength,
  validateEmail,
  isValidDate,
  isOverAge,
} from '../utils/validateUserInfo';

function UserInfo({ setPersona }) {
  const titles = ['Mr', 'Mrs', 'Ms', 'Miss', 'Dr', 'Mx'];

  // customer data capture
  const [title, setTitle] = useState('Title');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [DD, setDD] = useState('');
  const [MM, setMM] = useState('');
  const [YYYY, setYYYY] = useState('');

  const maximumLengthUsername = 50;

  // errors messages
  const [isFullNameValid, setIsFullNameValid] = useState(true);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showInvalidDateError, setShowInvalidDateError] = useState(false);
  const [showIsOver18Error, setShowIsOver18Error] = useState(false);
  let errorMsg = '';

  if (!isValidDate(DD, MM, YYYY) && showInvalidDateError) {
    errorMsg = <p className="error-message">Please enter a valid date...</p>;
  } else if (!isOverAge(DD, MM, YYYY) && showIsOver18Error) {
    errorMsg = (
      <p className="error-message">Sorry but you must be over 18 to apply.</p>
    );
  }

  useEffect(() => {
    setIsFullNameValid(validateFullNameLength(fullName, maximumLengthUsername));
  }, [fullName]);

  // derive from state
  function isFormValid() {
    if (
      validateFullNameLength(fullName, maximumLengthUsername) &&
      validateEmail(email) &&
      isValidDate(DD, MM, YYYY) &&
      isOverAge(DD, MM, YYYY)
    ) {
      return true;
    }
    return false;
  }

  function handleSubmit() {
    if (isFormValid()) {
      const userInfoObject = {
        title,
        fullName,
        email,
        DD,
        MM,
        YYYY,
      };
      localStorage.clear();
      // eslint-disable-next-line no-console
      console.log(userInfoObject); // Data for hypothetical hand-off to The Idol
      // no errors so we can Route to the messages page.
      setPersona(isOverAge(40) ? 'formal' : 'informal');
      // eslint-disable-next-line quotes
      navigate(`messages`);
    }
  }

  return (
    <main id="userInfo" role="main">
      <div id="page-wrapper">
        <h1 data-cy="main-title">Find the right life insurance for you</h1>
        <h2>Tell us about yourself</h2>

        <form
          onSubmit={ev => {
            ev.preventDefault();
            handleSubmit();
          }}
        >
          <JoinedForm>
            <Accordion>
              <div className="accorMain">
                <JoinedLeft tabIndex="0">
                  <div className="accorHead" data-test-id="user-title">
                    {title}
                    <span id="downArrow">▼</span>
                  </div>
                </JoinedLeft>
                <div className="accorBody" data-test-id="user-items">
                  {titles.map(honourific => (
                    <AccorElement
                      key={honourific}
                      onClick={() => {
                        setTitle(honourific);
                      }}
                      tabIndex="0"
                    >
                      {honourific}
                    </AccorElement>
                  ))}
                </div>
              </div>
            </Accordion>
            <JoinedInput
              required
              type="text"
              maxLength="100"
              data-test-id="user-full-name"
              placeholder="Your full name"
              aria-label="Full Name"
              onChange={ev => setFullName(ev.target.value)}
              value={fullName}
            />
          </JoinedForm>
          {isFullNameValid ? (
            ''
          ) : (
            <p className="error-message">
              Full Name must be {maximumLengthUsername} or less characters
            </p>
          )}

          <JoinedForm>
            <JoinedLeft>@</JoinedLeft>
            <JoinedInput
              required
              data-test-id="user-email"
              placeholder="Your email address"
              aria-label="Email"
              type="email"
              onChange={ev => setEmail(ev.target.value)}
              onBlur={() => setShowEmailError(true)}
              value={email}
            />
          </JoinedForm>
          {!validateEmail(email) && showEmailError ? (
            <p className="error-message">Please check your email address</p>
          ) : (
            ''
          )}

          <h2>When were you born?</h2>
          <div id="dateInput">
            <DateInput
              required
              type="text"
              pattern="[0-9]{2}"
              minLength="2"
              maxLength="2"
              data-test-id="user-dob-dd"
              placeholder="DD"
              aria-label="Day of birth"
              onChange={ev => setDD(ev.target.value)}
              value={DD}
            />

            <DateInput
              required
              type="text"
              pattern="[0-9]{2}"
              minLength="2"
              maxLength="2"
              data-test-id="user-dob-mm"
              placeholder="MM"
              aria-label="Month of birth"
              onChange={ev => setMM(ev.target.value)}
              value={MM}
            />

            <DateInput
              required
              type="text"
              pattern="[0-9]{4}"
              minLength="4"
              maxLength="4"
              data-test-id="user-dob-yyyy"
              placeholder="YYYY"
              aria-label="Year of birth"
              onChange={ev => setYYYY(ev.target.value)}
              value={YYYY}
              onBlur={() => {
                setShowInvalidDateError(true);
                setShowIsOver18Error(true);
              }}
            />
          </div>
          {errorMsg}
          <FormButton data-test-id="continue-button" type="submit">
            Continue
          </FormButton>
        </form>
      </div>
    </main>
  );
}

export default UserInfo;
