import styled, { css } from 'styled-components';

export default styled.img`
  float: right;
  width: 3vw;
  height: auto;
  margin-left: 1.4em;
  border-radius: 100%;
  ${props =>
    props.messageType === 'bot' &&
    css`
      float: left;
      margin-right: 1.4em;
    `}
`;
