/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
import React from 'react';

class Accordion extends React.Component {
  componentDidMount() {
    this.handleClick();
  }

  handleClick = () => {
    const acc = this._acc.children;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < acc.length; i++) {
      const a = acc[i];
      a.onclick = () => a.classList.toggle('accorActive');
    }
  }

  render() {
    return (
      <div ref={a => (this._acc = a)} onClick={this.handleClick}>
        {this.props.children}
      </div>
    );
  }
}

export default Accordion;
